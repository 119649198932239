import React from "react"
import Loadable from "react-loadable"

const ArtVillaLoadable = Loadable({
  loader: () => import("./artVilla"),
  loading() {
    return <div></div>
  },
})

export default () => (
  <div>
    <ArtVillaLoadable />
  </div>
)
